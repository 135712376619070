import React from "react";
import Button from "./Button";

const DownloadButtons = ({ editCurrentPage }) => {
  return (
    <div className="flex flex-col md:flex-row lg:flex-col items-center gap-3 lg:border rounded-b px-4 py-4">
      <a href="https://arxiv.org/abs/2407.14056" target="_blank">
        <Button label={"Read more on arXiv"} onClick={() => {}} />
      </a>
      {/* <a href="https://ai4bharat.iitm.ac.in/blog/Rasa/" target="_blank">
        <Button label={"Read our blog"} onClick={() => {}} />
      </a> */}
      <Button
        label={"Explore Rasa"}
        onClick={() => editCurrentPage("explore")}
      />
      <Button
        label={"Download Rasa"}
        onClick={() => editCurrentPage("download")}
      />
      {/* <Button
        label={"Download Rasa"}
        onClick={() => window.location.href = "https://ai4bharat.iitm.ac.in/rasa/v1/"}
      /> */}
    </div>
  );
};

export default DownloadButtons;
