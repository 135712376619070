import React from 'react';

const data = [
  { language: 'Assamese', speaker: 'Female', hours: 25 + 48 / 60 + 24 / 3600, utterances: 15046 },
  { language: 'Assamese', speaker: 'Male', hours: 28 + 33 / 60 + 7 / 3600, utterances: 16623 },
  { language: 'Bengali', speaker: 'Female', hours: 27 + 7 / 60 + 54 / 3600, utterances: 15575 },
  { language: 'Bengali', speaker: 'Male', hours: 26 + 13 / 60 + 32 / 3600, utterances: 15665 },
  { language: 'Bodo', speaker: 'Female', hours: 27 + 14 / 60 + 1 / 3600, utterances: 16329 },
  { language: 'Bodo', speaker: 'Male', hours: 23 + 31 / 60 + 9 / 3600, utterances: 13167 },
  { language: 'Dogri', speaker: 'Male', hours: 20 + 23 / 60 + 23 / 3600, utterances: 10069 },
  { language: 'Kannada', speaker: 'Female', hours: 26 + 56 / 60 + 39 / 3600, utterances: 14915 },
  { language: 'Kannada', speaker: 'Male', hours: 27 + 31 / 60 + 57 / 3600, utterances: 16002 },
  { language: 'Malayalam', speaker: 'Female', hours: 26 + 25 / 60 + 50 / 3600, utterances: 17017 },
  { language: 'Marathi', speaker: 'Female', hours: 27 + 33 / 60 + 41 / 3600, utterances: 15478 },
  { language: 'Marathi', speaker: 'Male', hours: 25 + 22 / 60 + 20 / 3600, utterances: 14493 },
  { language: 'Nepali', speaker: 'Female', hours: 28 + 39 / 60 + 15 / 3600, utterances: 16016 },
  { language: 'Sanskrit', speaker: 'Male', hours: 26 + 14 / 60 + 31 / 3600, utterances: 11030 },
  { language: 'Tamil', speaker: 'Female', hours: 21.70, utterances: 14402 }, // New Tamil data added
];

const DataTable = () => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead className="bg-orange-300 text-gray-800">
          <tr>
            <th className="py-2 px-4 text-left font-semibold">Language</th>
            <th className="py-2 px-4 text-left font-semibold">Speaker</th>
            <th className="py-2 px-4 text-left font-semibold">Hours</th>
            <th className="py-2 px-4 text-left font-semibold">Utterances</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-orange-50' : 'bg-white'}>
              <td className="py-2 px-4 border-b border-orange-200">{row.language}</td>
              <td className="py-2 px-4 border-b border-orange-200">{row.speaker}</td>
              <td className="py-2 px-4 border-b border-orange-200">{row.hours.toFixed(2)}</td>
              <td className="py-2 px-4 border-b border-orange-200">{row.utterances}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;