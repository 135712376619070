import { useState, useContext, useEffect } from "react";
import SubHeading from "./SubHeading";
import metadata from "../assets/metadata.json";
import SearchableSelection from "./SearchableSelection";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const Filter = () => {
  const { data, editFilterOptions, addFilteredAudioDetails } =
    useContext(IndicVoiceContext);
  const { language, gender, style } = data.filterOptions;

  const modifySelection = (id, values) => {
    const tmpList = values.map((value) => value && value.value);
    editFilterOptions({ [id]: tmpList });
  };

  useEffect(() => {
    addFilteredAudioDetails(data.audioDetails);
  }, [language, gender, style, data.audioDetails]);

  return (
    <div className="rounded bg-white p-4 grid">
      <SubHeading heading={"Data"} />
      <div className="grid grid-cols-1 lg:grid-cols-3 items-center gap-2 ">
        <SearchableSelection
          id="language"
          values={metadata.languages}
          placeholder="Language"
          selected={language}
          setSelected={(id, value) => {
            modifySelection(id, value);
          }}
          isDisabled={false}
          isMulti={true}
        />
        <SearchableSelection
          id="gender"
          values={metadata.gender}
          placeholder="Gender"
          selected={gender}
          isDisabled={false}
          setSelected={(id, value) => {
            modifySelection(id, value);
          }}
          isMulti={true}
        />
        <SearchableSelection
          id="style"
          values={metadata.style}
          setSelected={(id, value) => {
            modifySelection(id, value);
          }}
          placeholder="Style"
          selected={style}
          isDisabled={false}
          isMulti={true}
        />
      </div>
    </div>
  );
};

export default Filter;
