import React, { useEffect, useState } from "react";
import metadata from "../assets/metadata.json";

const DownloadListItem = ({ gender, token }) => {
  const [languages, setLanguages] = useState([]);
  const [datasetStatus, setDatasetStatus] = useState({});

  useEffect(() => {
    const sortedLanguages = Object.keys(metadata.languages).sort();
    setLanguages(sortedLanguages);

    const checkDatasets = async () => {
      try {
        const response = await fetch(`https://rasa.ai4bharat.org/backend/dataset_exists?token=${token}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        const status = {};
        sortedLanguages.forEach(language => {
          const filename = `${language}_${gender}.tar.gz`;
          status[language] = data.message.includes(filename);
        });
        
        setDatasetStatus(status);
      } catch (error) {
        console.error("Error checking datasets:", error);
        // Set all datasets to false in case of error
        const status = Object.fromEntries(sortedLanguages.map(lang => [lang, false]));
        setDatasetStatus(status);
      }
    };

    checkDatasets();
  }, [gender, token]);

  const createUrl = (gender, language) => {
    return `https://rasa.ai4bharat.org/backend/download_dataset/${language}_${gender}.tar.gz?token=${token}`;
  };

  return (
    <div className="p-2 min-w-fit">
      <span className="text-sm font-bold capitalize">
        Gender: <span className="uppercase">{gender}</span>
      </span>
      <div className="flex flex-row rounded">
        {languages.map((language, index, row) => (
          <div
            key={language}
            className="flex flex-col gap-2 text-center items-center"
          >
            <p
              className={`p-2 px-4 font-medium text-sm bg-slate-200 ${
                index === 0 ? "rounded-tl" : ""
              } ${row.length - 1 === index ? "rounded-tr" : ""}`}
            >
              {language}
            </p>
            {datasetStatus[language] === undefined ? (
              <p className="text-xs">Loading...</p>
            ) : datasetStatus[language] ? (
              <a href={createUrl(gender, language)}
                className="text-xs text-blue-400 underline"
              >
                Link
              </a>
            ) : (
              <p className="text-xs">-</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DownloadListItem;