const TranscriptionBox = ({ heading, audioDetails, timestamp }) => {
  return (
    <div className="p-4 bg-white rounded shadow-md">
      <p className="text-lg font-medium capitalize underline mb-2">{heading}</p>
      <div className={audioDetails["language"] === "Urdu" || audioDetails["language"] === "Kashmiri" ? "rtl-grid" : ""}>
        {audioDetails[heading].map((section) => (
          <p
            key={section.start}
            className={`text-lg ${section.start <= timestamp && section.end >= timestamp ? "text-green-500" : "text-slate-600"}`}
          >
            {section.text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default TranscriptionBox;

