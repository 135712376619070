import React from "react";
import SubHeading from "./SubHeading";

const Details = () => {
  return (
    <div className="flex flex-col h-full bg-white p-4 md:rounded">
      <SubHeading heading={"Rasa"} />
      <p className="text-sm">
      We introduce Rasa, the first high-quality multilingual expressive Text-to-Speech (TTS) dataset 
      for any Indian language. It comprises a minimum of 20 hours per speaker with a target of covering 
      a female and male voice for each of the 22 officially recognized languages of India. In our initial 
      version, we explore a practical recipe for collecting high-quality data for resource-constrained 
      languages, prioritizing easily obtainable neutral data alongside smaller amounts of expressive data. 
      This approach enables us to extend our dataset to encompass a diverse array of speaking styles and contexts. 
      These include neutral readings from Wikipedia and IndicTTS texts, expressive speech capturing the six 
      Ekman emotions (happy, sad, angry, fear, disgust, and surprise), as well as command-based interactions 
      from platforms like Alexa, BigBasket, UMANG, and DigiPay. Additionally, Rasa includes natural conversations 
      on various topics, news-reading, and narration from book readings. Currently, we release the data for 
      8 speaker-language pairs. Through this release, we aim to provide a valuable resource for developing 
      expressive TTS models in multilingual settings for the officially recognized languages of India.
      </p>
    </div>
  );
};

export default Details;
