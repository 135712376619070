import React, { useContext } from "react";
import Heading from "../components/Heading";
import Details from "../components/Details";
import DownloadButtons from "../components/DownloadButtons";
import Contact from "../components/Contact";
import DataTable from "../components/DataTable";
import { IndicVoiceContext } from "../state/IndicVoiceContext";

const License = () => (
  <div className="bg-white shadow-md rounded-lg p-4 mt-4 text-center">
    <p className="text-sm text-gray-600">
      <span className="bg-black-900 text-xl font-semibold">License: CC-By-4.0</span>
    </p>
  </div>
);

const Home = () => {
  const { editCurrentPage } = useContext(IndicVoiceContext);

  return (
    <div className="bg-slate-100 pb-16 min-h-screen">
      <div className="bg-slate-100 md:container md:mx-auto">
        <Heading />
        <div className="mb-4"></div>
        <div className="grid grid-rows-1 lg:grid-cols-9 gap-4 items-stretch">
          <div className="lg:col-span-8">
            <Details />
          </div>
          <div className="flex flex-cols h-full items-center justify-center p-2 bg-white lg:bg-slate-200 border-0 border-orange-300 md:rounded">
            <DownloadButtons editCurrentPage={editCurrentPage} />
          </div>
        </div>
        <div className="mb-4"></div>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <h2 className="text-xl font-semibold p-3 bg-orange-300 text-gray-800">Data Statistics</h2>
          <DataTable />
        </div>
        <div className="mb-4"></div>
        <Contact />
        <License />
      </div>
    </div>
  );
};

export default Home;
